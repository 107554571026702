/*
 * @Description: What's this for
 * @Autor: zhangzhang
 * @Date: 2021-10-12 15:38:29
 * @LastEditors: WangYuan
 * @LastEditTime: 2021-11-12 17:16:16
 */
export default {
  label: '魔方配置',
  type: 'cube',
  property: '',
  value: {
    model: 'custom',
    row: 3,
    list: [],
  },
};
