/*
 * @Description: What's this for
 * @Autor: zhangzhang
 * @Date: 2021-10-12 15:38:29
 * @LastEditors: WangYuan
 * @LastEditTime: 2021-11-12 17:15:26
 */
export default {
  label: '输入框',
  type: 'string',
  property: '',
  value: '',
  options: {
    placeholder: '请输入',
  },
};
