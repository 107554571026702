<!--
 * @Description: What's this for
 * @Autor: zhangzhang
 * @Date: 2021-10-08 16:34:00
 * @LastEditors: WangYuan
 * @LastEditTime: 2021-10-18 09:59:07
-->
<template>
  <custom-schema-template-set></custom-schema-template-set>
</template>

<script>
import customSchemaTemplateSet from '@/custom-schema-template-set/index';
export default {
  name: 'schema',
  components: {
    customSchemaTemplateSet,
  },
};
</script>
