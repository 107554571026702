/*
 * @Description: What's this for
 * @Autor: zhangzhang
 * @Date: 2021-10-12 15:38:29
 * @LastEditors: WangYuan
 * @LastEditTime: 2021-11-12 17:15:55
 */
export default {
  label: '数字',
  type: 'number',
  property: '',
  value: 0,
  options: {
    min: 0,
    max: 50,
  },
};
