/*
 * @Description: What's this for
 * @Autor: zhangzhang
 * @Date: 2021-10-12 15:38:29
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2022-04-26 15:24:46
 */
export default {
  label: '数组',
  type: 'array',
  property: '',
  options: {
    note: '',
    limit: 10,
  },
  child: [],
  value: [],
};
