<!--
 * @Description: What's this for
 * @Autor: zhangzhang
 * @Date: 2021-10-13 14:14:54
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2022-05-19 16:20:53
-->
<template>
  <div>
    <config-wrap title="必输配置">
      <config-item label="属性名">
        <el-input v-model="content.curComponent.property" placeholder="请输入属性名" size="small"></el-input>
      </config-item>
    </config-wrap>
    <config-wrap title="基础配置">
      <config-item label="标题">
        <el-input v-model="content.curComponent.label" placeholder="请输入标题" size="small"></el-input>
      </config-item>
      <config-item label="默认图片">
        <Imgpond v-model="content.curComponent.value" :count="1" />
      </config-item>
    </config-wrap>
  </div>
</template>

<script>
export default {
  name: 'SchemaUploadConfig',

  inject: ['content'],
  methods: {
    async imgSuccess(base64) {
      let result = await this.$aliOSS(base64);
      if (result) {
        console.log(result.url);
        content.curComponent.value = result.url;
      } else {
        this.$message.error('图片失败');
        content.curComponent.value = '';
      }

      // const res = await http.POST('/jw-admin', {
      //   module: 'file',
      //   operation: 'addFile',
      //   base64: base64,
      // });
      // if (res) {
      //   content.curComponent.value = res.fileID;
      // } else {
      //   this.$message.error('图片失败');
      //   content.curComponent.value = '';
      // }
    },
  },
};
</script>
