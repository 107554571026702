/*
 * @Description: What's this for
 * @Autor: zhangzhang
 * @Date: 2021-10-12 15:38:29
 * @LastEditors: WangYuan
 * @LastEditTime: 2021-11-12 17:15:51
 */
export default {
  label: '对象',
  type: 'object',
  property: '',
  child: [],
  fields: {
    value: '',
  },
};
